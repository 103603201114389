html {
  height: 100%;
}

body, #root {
  margin: 0;
  min-height: 100%;
}

.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.ant-collapse-header{ 
  font-size: 24px;
  font-weight: 500;
}